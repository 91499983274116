<template>
  <div>
    <graphTitle :title="'临时停车次数趋势'">
      <el-tooltip placement="top" content="展示所选期间范围的临时停车数量">
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="circularChart" class="circularChart"></div>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    tableData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
    arrearageHistorytableData: {
      default: function () {
        return {};
      },
    },
    temporaryParktableData: {
      default: function () {
        return {};
      },
    },
    nightParktableData: {
      default: function () {
        return {};
      },
    },
    paySummaryReporttableData: {
      default: function () {
        return {};
      },
    },
    type: {
      default: "c1",
    },
  },
  data() {
    return {
      totalNum: 0,
      nameList: [],
      dataList: [],
      // 日期（共用）
      dateList: [],
      // 夜间停车 （以下）
      nightParknameList: [],
      nightParkdataList: [],
      // 营收总报表（以下）
      paySummaryReportdataList: [],
      seriesValue1: [
        {
          name: "应收总额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
        {
          name: "欠费额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "red",
          },
          itemStyle: {
            normal: {
              color: "red",
            },
          },
        },
      ],
      seriesValue2: [
        {
          name: "临时停车次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
        {
          name: "长时停车次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#5A90FF",
          },
          itemStyle: {
            normal: {
              color: "#5A90FF",
            },
          },
        },
      ],
      seriesValue3: [
        {
          name: "日间停车时长",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#C71585",
          },
          itemStyle: {
            normal: {
              color: "#C71585",
            },
          },
        },
        {
          name: "夜间停车时长",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#008000",
          },
          itemStyle: {
            normal: {
              color: "#008000",
            },
          },
        },
        {
          name: "纯夜间停车时长",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#DAA520",
          },
          itemStyle: {
            normal: {
              color: "#DAA520",
            },
          },
        },
      ],
      seriesValue4: [
        {
          name: "历史营收趋势",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      textTitle1: "欠费金额趋势",
      textTitle2: "",
      textTitle3: "日间/夜间停车时长趋势",
      textTitle4: "历史营收趋势",
    };
  },
  components: {
    graphTitle,
  },
  watch: {
    tableData: {
      handler(value) {
        this.totalNum = 0;
        this.dataList = [];
        this.nameList = [];
        for (var i = 0; i < value.length; i++) {
          this.totalNum += value[i].value * 1;
          this.nameList.push(value[i].dataDate);
          this.dataList.push({
            name: value[i].name,
            value: Number(value[i].value / 100).toFixed(2),
          });
        }
        this.totalNum = (this.totalNum / 100).toFixed(2);
        this.drawGraph();
      },
      deep: true,
    },
    // 欠费历史分析（欠费金额趋势）
    arrearageHistorytableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue1[0].data = [];
        this.seriesValue1[1].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 应收金额
          this.seriesValue1[0].data.push(Number(value[i].shouldPay / 100).toFixed(2));
          // 欠费额
          this.seriesValue1[1].data.push(Number(value[i].arrearageAmount / 100).toFixed(2));
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 临时停车统计（过去30日临时停车趋势）
    temporaryParktableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue2[0].data = [];
        this.seriesValue2[1].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 过去30日临时停车趋势数据
          this.seriesValue2[0].data.push(value[i].shortParkNum);
          this.seriesValue2[1].data.push(value[i].longParkNum);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 夜间停车记录趋势
    nightParktableData: {
      handler(value) {
        this.totalNum = 0;
        this.nightParknameList = [];
        this.dateList = [];
        this.seriesValue3[0].data = [];
        this.seriesValue3[1].data = [];
        this.seriesValue3[2].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 日间停车时长
          this.seriesValue3[0].data.push(value[i].daytimeParkTime);
          // 夜间停车时长
          this.seriesValue3[1].data.push(value[i].nightParkTime);
          // 纯夜间停车时长
          this.seriesValue3[2].data.push(value[i].pureNightParkTime);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 营收总报表的营收趋势
    paySummaryReporttableData: {
      handler(value) {
        this.totalNum = 0;
        let dateArr = [];
        this.dateList = [];
        this.seriesValue4[0].data = [];
        for (var i = 0; i < value.length; i++) {
          // this.totalNum += value[i].value * 1
          dateArr.push(value[i].dateDate);
          this.seriesValue4[0].data.push(Number(value[i].totalAmount / 100).toFixed(2));
        }
        this.dateList = this.uniq(dateArr);
        this.drawGraph();
      },
      deep: true,
    },
  },
  methods: {
    // 去重
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph() {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById("circularChart"));
      let option = {
        title: {
          text:
            that.type == "c1"
              ? that.textTitle1
              : that.type == "c2"
              ? that.textTitle2
              : that.type == "c3"
              ? that.textTitle3
              : that.textTitle4,
        },
        legend: {
          data:
            that.type == "c1"
              ? ["应收总额", "欠费额"]
              : that.type == "c2"
              ? ["临时停车次数", "长时停车次数"]
              : that.type == "c3"
              ? ["日间停车时长", "夜间停车时长", "纯夜间停车时长"]
              : ["历史营收趋势"],
        },
        tooltip: {
          trigger: "axis",
          formatter: (parmas) => {
            // console.log(parmas);
            let str = "";
            let count = parmas[0]?.value + (parmas[1]?.value || 0);

            str += `${parmas[0].axisValue}<br/>总停车次数: ${count}次<br/>`;
            parmas.forEach((e) => {
              let pre = "0.00%";
              if (count == 0) {
                pre = "0.00%";
              } else {
                pre = `${((e.value / count) * 100).toFixed(2)}%`;
              }
              str += `${e.marker} ${e.seriesName}: ${e.value}次 占比:${pre}<br/>`;
            });
            return str;
          },
        },
        toolbox: {
          show: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateList,
        },
        yAxis: {
          type: "value",
          name: "停车次数(次)",
        },
        series:
          that.type == "c1"
            ? that.seriesValue1
            : that.type == "c2"
            ? that.seriesValue2
            : that.type == "c3"
            ? that.seriesValue3
            : that.seriesValue4,
      };
      circularChart.setOption(option, true);
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
</style>
